<template>
  <div class="privacy contentWrapper">
    <h1>Privacy Policy</h1>
    <p>Updated December 1, 2015</p>
    <div class="content">
      <h3>Use of your Personal Information</h3>
      <p>We use your personal information for four primary purposes:</p>
      <ul>
        <li>
          To make the site easier for you to use by not making you enter your
          personal information more than once.
        </li>
        <li>
          To help you quickly find software, services or information on
          NaturalPoint.com.
        </li>
        <li>To help us create and deliver content most relevant to you.</li>
        <li>
          To alert you to product upgrades, special offers, updated information
          and other new services from NaturalPoint.
        </li>
      </ul>
      <p>
        We occasionally hire other companies to provide limited services on our
        behalf, including packaging, mailing and delivering purchases, answering
        customer questions about products or services, sending postal mail and
        processing event registration. We will only provide those companies the
        information they need to deliver the service, and they are prohibited
        from using that information for any other purpose.
      </p>
      <p>
        NaturalPoint will disclose your personal information, without notice,
        only if required to do so by law or in the good faith belief that such
        action is necessary to: (a) conform to the edicts of the law or comply
        with legal process served on NaturalPoint or the site; (b) protect and
        defend the rights or property of NaturalPoint and its family of Web
        sites, and, (c) act in urgent circumstances to protect the personal
        safety of users of NaturalPoint, its Web sites, or the public.
      </p>
      <h3>Control of your Personal Information</h3>
      <p>
        When you register, or otherwise give us personal information,
        NaturalPoint.com will not share that information with third parties
        without your permission, other than for the limited exceptions already
        listed. It will only be used for the purposes stated above. NaturalPoint
        may send out periodic e-mails informing you of technical service issues
        related to a product or service you requested. You will be able to
        choose to unsubscribe from these mailings, although they are considered
        an essential part of the service you have chosen.
      </p>
      <h3>Access to your Personal Information</h3>
      <p>
        We will provide you with the means to ensure that your personal
        information is correct and current. You may review and update this
        information at any time.
      </p>
      <h3>Security of your Personal Information</h3>
      <p>
        NaturalPoint.com strictly protects the security of your personal
        information and honors your choices for its intended use. We carefully
        protect your data from loss, misuse, unauthorized access or disclosure,
        alteration, or destruction.
      </p>
      <p>
        Your personal information is never shared outside the company without
        your permission, except under conditions explained above. Inside the
        company, data is stored in password-controlled servers with limited
        access. Your information may be stored and processed in the United
        States or any other country where NaturalPoint, its subsidiaries,
        affiliates or agents are located.
      </p>
      <p>
        You also have a significant role in protecting your information. No one
        can see or edit your personal information without knowing your Passport
        and password, so do not share these with others.
      </p>
      <h3>Protection of Children's Personal Information</h3>
      <p>
        The NaturalPoint.com site does not publish content that is targeted to
        children.
      </p>
      <h3>Use of Cookies</h3>
      <p>
        To ensure we are publishing content customers need and want,
        NaturalPoint.com collects aggregated site-visitation statistics using
        cookies. We do not track individuals' use of the site.
      </p>
      <p>
        When someone visits the site, a cookie is placed on the customer's
        machine (if the customer accepts cookies) or is read if the customer has
        visited the site previously. If you choose to not have your browser
        accept cookies from the NaturalPoint.com Web site, you will be able to
        view the text on the screens, however you will not experience a
        personalized visit, you will not be able to use the online store, you
        will not be able to apply product discounts, nor will you be able to
        subscribe to the service offerings on the site.
      </p>
      <h3>Changes to this Statement</h3>
      <p>
        NaturalPoint will occasionally update this privacy statement. When we
        do, we will also revise the "last updated" date at the top of the
        privacy statement. For material changes to this Statement, NaturalPoint
        will notify you by placing prominent notice on the Web site.
      </p>
      <h3>Contact Information</h3>
      <p>
        NaturalPoint welcomes your comments regarding this Statement of Privacy,
        please contact us by e-mail, or postal mail.
      </p>
    </div>
  </div>
  <!-- END privacy -->
</template>
<script>
export default {
  name: "Privacy"
};
</script>
<style lang="scss" scoped>
.privacy {
  text-align: center;

  h1 {
    margin-bottom: 0px;
  }
  h1 ~ p {
    margin-top: 0px;
    margin-bottom: 45px;
  }

  h3 {
    margin-bottom: 0;
    color: #ffffff;
    font-weight: 700;
  }

  h3 ~ p {
    margin-top: 0px;
  }

  .content {
    text-align: left;
    padding-bottom: 40px;
  }
}
</style>
